<template>
  <div class="attribute-wrapper">
    <Breadcrumbs>
      <template slot="action-buttons">
        <v-btn class="pl-5 pr-5" depressed color="primary" @click="doCreate"
          >添加属性</v-btn
        >
      </template>
    </Breadcrumbs>
    <div class="main-top d-flex justify-space-between content-wrapper">
      <TabList
        class="tablist"
        @tabEvent="tabEvent($event)"
        :tabList="tabList"
      />
    </div>
    <TableList
      :attributes="attributes"
      :tabId="tabId"
      :drawer.sync="drawer"
      @editEvent="editEvent($event)"
      @refreshListEvent="refreshListEvent($event)"
      class="content-wrapper"
    />
    <v-navigation-drawer app temporary v-model="drawer" width="650px" right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ displayType === "CREATE" ? "新建属性" : "修改属性" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-form class="pa-4" ref="form">
        <v-row no-gutters dense style="border-bottom:none!important;">
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text">*</span>
              属性代码
            </v-subheader>
          </v-col>
          <v-col cols="9" v-if="displayType === 'CREATE'">
            <v-text-field
              v-model="name"
              rows="1"
              required
              :rules="rules.isFull"
            ></v-text-field>
          </v-col>
          <v-col cols="9" v-else class="mt-3">
            {{ name }}
          </v-col>
        </v-row>
        <v-row no-gutters dense style="border-bottom:none!important;">
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text">*</span>
              属性名称
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="display_name"
              required
              :rules="rules.isFull"
              rows="1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense style="border-bottom:none!important;">
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text">*</span>
              类型
            </v-subheader>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="6"
            v-if="displayType === 'CREATE'"
          >
            <v-select
              :items="types"
              :label="value_type ? '' : '请选择'"
              item-text="name"
              item-value="id"
              v-model="value_type"
              required
              :rules="rules.isFull"
              @change="getvalue_typeSelected(value_type)"
            ></v-select>
          </v-col>
          <v-col cols="9" v-else class="mt-5">
            {{ allTypes[value_type] }}
          </v-col>
        </v-row>
        <v-row no-gutters dense style="border-bottom:none!important;">
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text">*</span>
              描述
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="description"
              required
              :rules="rules.isFull"
              rows="1"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense style="border-bottom:none!important;">
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text" v-if="required">*</span>
              默认值
            </v-subheader>
          </v-col>
          <v-col cols="9" v-if="!required">
            <v-text-field
              v-model="defaultVal"
              placeholder="无默认值"
              :append-icon="defaultVal ? 'mdi-close-circle' : null"
              @click:append="clearDefaultVal"
              rows="1"
              class="loginname"
            ></v-text-field>
          </v-col>
          <v-col cols="9" v-else>
            <v-text-field
              v-model="defaultVal"
              placeholder="无默认值"
              :append-icon="defaultVal ? 'mdi-close-circle' : null"
              @click:append="clearDefaultVal"
              required
              :rules="rules.isFull"
              rows="1"
              class="loginname"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters dense style="border-bottom:none!important;">
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text">*</span>
              是否必要
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-checkbox class="v-label" v-model="required"></v-checkbox>
          </v-col>
        </v-row>
        <v-row no-gutters dense style="border-bottom:none!important;">
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text">*</span>
              唯一值
            </v-subheader>
          </v-col>
          <v-col cols="9" v-if="!(displayType === 'EDIT')">
            <v-checkbox
              class="v-label"
              v-model="unique"
              :disabled="displayType === 'EDIT'"
            ></v-checkbox>
          </v-col>
          <v-col v-else cols="9" class="mt-3" style="align-self: center;">
            {{ unique ? "是" : "否" }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          dense
          v-if="displayType === 'EDIT'"
          style="align-items:center;"
        >
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text"></span>
              内建属性
            </v-subheader>
          </v-col>
          <v-col cols="9" class="mt-3">
            {{ builtin ? "是" : "否" }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          dense
          style="align-items:center;"
          v-if="displayType === 'EDIT'"
        >
          <v-col cols="3" class="mt-2">
            <v-subheader>
              <span class="red--text"></span>
              多值
            </v-subheader>
          </v-col>
          <v-col cols="9" class="mt-3">
            {{ multiple ? "是" : "否" }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex" cols="3"></v-col>
          <v-col cols="9" class="text-left">
            <v-btn elevation="4" medium color="primary" @click="submit">
              {{ displayType === "CREATE" ? "新建" : "修改" }}</v-btn
            >
            <v-btn
              class="ml-6"
              elevation="0"
              medium
              color="gary"
              @click="cancel"
              >取消</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import TableList from "./component/TableList";

export default {
  name: "AttributeManage",
  data() {
    return {
      tabId: 0,
      tabList: [
        {
          id: 0,
          name: "用户"
        },
        {
          id: 1,
          name: "用户组"
        },
        {
          id: 2,
          name: "组织机构"
        }
      ],
      attributes: [],
      drawer: null,
      displayType: "CREATE",
      editId: null,
      verifyResult: false,
      name: "",
      display_name: "",
      description: "",
      value_type: "",
      defaultVal: null,
      required: false,
      unique: false,
      builtin: false,
      multiple: false,
      form: {},
      allTypes: {
        STRING: "字符串类型",
        JSON: "JSON类型",
        BOOLEAN: "布尔值",
        INTEGER: "数字类型",
        DATETIME: "日期时间类型",
        DATE: "日期类型"
      },
      types: [
        {
          id: "STRING",
          name: "字符串类型"
        },
        {
          id: "JSON",
          name: "JSON类型"
        },
        {
          id: "BOOLEAN",
          name: "布尔值"
        },
        {
          id: "INTEGER",
          name: "数字类型"
        },
        {
          id: "DATETIME",
          name: "日期时间类型"
        },
        {
          id: "DATE",
          name: "日期类型"
        }
      ],
      rules: {
        // isFull: [(v) => fullForm(v) || "此项为必填项"],
        isFull: [v => !!v || "此项为必填项"]
      }
    };
  },
  created() {
    this.switchFetchList(this.tabId);
  },
  methods: {
    getCurIdData(id) {
      this.$http
        .get(`api/attribute/${id}`)
        .delegateTo(api_request)
        .then(data => {
          this.name = data.name;
          this.display_name = data.display_name;
          this.description = data.description;
          this.value_type = data.value_type;
          this.defaultVal = data.default;
          this.unique = data.unique;
          this.required = data.required;
          this.builtin = data.builtin;
          this.multiple = data.multiple;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    getvalue_typeSelected(val) {
      this.value_type = val;
    },
    clearDefaultVal() {
      this.defaultVal = null;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.displayType === "CREATE" ? this.toCreate() : this.toEdit();
      }
    },
    cancel() {
      this.drawer = !this.drawer;
    },
    toCreate() {
      let tabItems = ["ACCOUNT", "GROUP", "DEPARTMENT"];
      let params = {
        entity_type: tabItems[this.tabId],
        default: this.defaultVal != null ? this.defaultVal : null,
        required: this.required ? true : false,
        display_name: this.display_name,
        unique: this.unique ? true : false,
        description: this.description,
        name: this.name,
        value_type: this.value_type
      };
      this.$http
        .post("api/attribute/create", params)
        .delegateTo(api_request)
        .then(() => {
          this.drawer = !this.drawer;
          this.switchFetchList(this.tabId);
        })
        .catch(({ code, message }) => {
          this.drawer = !this.drawer;
          throw `新建失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    toEdit() {
      let params = {
        required: this.required ? true : false,
        default: this.defaultVal != null ? this.defaultVal : null,
        display_name: this.display_name,
        description: this.description,
        name: this.name,
        value_type: this.value_type
      };
      this.$http
        .patch(`api/attribute/${this.editId}/modify`, params)
        .delegateTo(api_request)
        .then(() => {
          this.drawer = !this.drawer;
          this.switchFetchList(this.tabId);
          return "修改成功";
        })
        .catch(({ code, message }) => {
          this.drawer = !this.drawer;
          throw `修改失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    tabEvent(id) {
      this.tabId = id;
      this.switchFetchList(this.tabId);
    },
    editEvent({ id, displayType }) {
      this.editId = id;
      this.displayType = displayType;
      this.getCurIdData(this.editId);
    },
    refreshListEvent(id) {
      this.switchFetchList(id);
    },
    fetchAccountList() {
      this.$http
        .get(`api/attribute/account/list`)
        .delegateTo(api_request)
        .then(data => {
          this.attributes = data.attributes;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchGroupList() {
      this.$http
        .get(`api/attribute/group/list`)
        .delegateTo(api_request)
        .then(data => {
          this.attributes = data.attributes;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchDepartmentList() {
      this.$http
        .get(`api/attribute/department/list`)
        .delegateTo(api_request)
        .then(data => {
          this.attributes = data.attributes;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    doCreate() {
      this.$refs.form.reset();
      this.drawer = !this.drawer;
      this.displayType = "CREATE";
      this.name = "";
      this.display_name = "";
      this.value_type = "";
      this.description = "";
      this.defaultVal = null;
      this.required = false;
      this.unique = false;
      this.builtin = false;
      this.multiple = false;
    },
    switchFetchList(tabId) {
      switch (tabId) {
        case 1:
          this.fetchGroupList();
          break;
        case 2:
          this.fetchDepartmentList();
          break;

        default:
          this.fetchAccountList();
          break;
      }
    }
  },
  components: {
    Breadcrumbs,
    TabList,
    TableList
  }
};
</script>

<style lang="less" scoped>
.attribute-wrapper {
  .main-top {
    box-sizing: border-box;
    align-items: center;
  }
  .create-wrapper {
    .theme-title {
      height: 50px;
      line-height: 50px;
      box-sizing: border-box;
      padding-left: 12px;
    }
    .theme-content {
      box-sizing: border-box;
      padding: 20px;
      .verify_notice {
        width: 340px;
        text-align: center;
        background-color: red;
        color: #fff;
        height: 40px;
        line-height: 40px;
        border-radius: 10px;
      }
      .every-item {
        display: flex;
        height: 14px;
        align-items: center;
        margin-top: 50px;
        label {
          flex: 0 0 130px;
          text-align: left;
          color: #666;
        }
      }
    }

    .submit-con {
      text-align: center;
      margin-top: 100px;
      button {
        width: 120px;
      }
      button:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
}

/deep/.loginname .v-icon.v-icon {
  font-size: 16px;
}
</style>
