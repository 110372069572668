<template>
  <div class="accountlist">
    <v-simple-table>
      <thead>
        <tr>
          <th class="text-left pl-4">
            序号
          </th>
          <th class="text-left">
            属性代码
          </th>
          <th class="text-left">
            属性名称
          </th>
          <th class="text-left">
            类型
          </th>
          <th class="text-left">
            描述
          </th>
          <th class="text-left">
            是否必要
          </th>
          <th class="text-left">
            唯一值
          </th>
          <th class="text-left">
            内建属性
          </th>
          <th class="text-left">
            多值
          </th>
          <th class="text-center" width="106">
            操作
          </th>
        </tr>
      </thead>
      <tbody v-if="attributes.filter(item => !item.virtual).length > 0">
        <tr
          v-for="item in attributes.filter(item => !item.virtual)"
          :key="item.id"
        >
          <td class="pl-4">{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.display_name }}</td>
          <td>{{ value_typeDatas[item.value_type] }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.required ? "是" : "否" }}</td>
          <td>{{ item.unique ? "是" : "否" }}</td>
          <td>{{ item.builtin ? "是" : "否" }}</td>
          <td>{{ item.multiple ? "是" : "否" }}</td>
          <td class="text-center">
            <v-btn
              v-if="!(item.builtin || item.virtual)"
              icon
              elevation="0"
              @click.stop="toEdit(item.id)"
            >
              <v-icon>
                mdi-square-edit-outline
              </v-icon>
            </v-btn>

            <v-btn
              v-if="!(item.builtin || item.virtual)"
              icon
              elevation="0"
              color="#c0c0c0"
              @click.stop="toDelete(item)"
            >
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="text-center">
          <td
            colspan="10"
            style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
          >
            暂无数据
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import ConfirmBox from "@/components/ConfirmBox";

export default {
  name: "TableList",
  props: {
    attributes: {
      type: Array
    },
    drawer: {
      type: Boolean
    },
    tabId: {
      type: Number
    }
  },
  data() {
    return {
      value_typeDatas: {
        STRING: "字符串类型",
        JSON: "JSON类型",
        BOOLEAN: "布尔值",
        INTEGER: "数字类型",
        DATETIME: "日期时间类型",
        DATE: "日期类型"
      }
    };
  },
  methods: {
    toEdit(id) {
      this.$emit("update:drawer", true);
      this.$emit("editEvent", { id, displayType: "EDIT" });
    },
    toDelete(item) {
      this.$refs.confirm
        .showConfirm({
          contentText: `您确认要删除${item.name}？`,
          callback: () => {
            return this.$http
              .delete(`api/attribute/${item.id}/delete`)
              .delegateTo(api_request)
              .then(() => {
                this.$emit("refreshListEvent", this.tabId);
                return "删除成功";
              })
              .catch(({ code, message }) => {
                throw `删除失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
                  "api." + typeof message === "string"
                    ? message
                    : JSON.stringify(message)
                )}`;
              });
          }
        })
        .catch(() => {
          return;
        });
    }
  },
  components: {
    ConfirmBox
  }
};
</script>

<style lang="less" scoped>
/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
